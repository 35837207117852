.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;
  font-weight: 300;
  line-height: 130%;

  pre {
    margin-top: 64px;
  }
}

.strikethrough-card {
  width: 90%;
  max-width: 927px;

  pre {
    white-space: normal;
  }
}

.nested-text-group > div {
  width: 100%;
}

@media screen and (max-width: 436px) {
  .subtitle {
    display: none;
  }
}

@media screen and (max-width: 325px) {
  .title {
    font-size: 46px;
  }
}